import React, { useEffect } from 'react'
import {
  CardMeta,
  CardHeader,
  CardDescription,
  CardContent,
  Button,
  Card,
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Form,
  FormField,
  Checkbox
} from 'semantic-ui-react'

const test = async () => {
  const response = await fetch('http://localhost:3001/api/func/list', {
      method: 'GET'
    });

  const data = await response.json()
  console.log(data)
}

const login = async () => {
  console.log('login')
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Access-Control-Allow-Origin", "*");
  myHeaders.append("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept");

  var raw = JSON.stringify({
    "email": "shen@admin.com",
    "password": "123456"
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  fetch("https://app.kycel.com/v1/login", requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));

  // const response = await fetch('https://app.kycel.com/v1/login', {
  //     method: 'POST',
  //     headers: {
  //       'Access-Control-Allow-Origin': 'https://example.com',
  //       'Access-Control-Allow-Credentials' : true,
  //       'Access-Control-Allow-Methods': 'GET, OPTIONS',
  //       'Access-Control-Allow-Headers': 'office',
  //       'Content-Type': 'application/json',
  //   },
  //     // body: '{\n\t"email":"shen@admin.com",\n\t"password": "123456"\n}',
  //     // body: JSON.stringify({
  //     //   'email': 'shen@admin.com',
  //     //   'password': '123456'
  //     // })
  //   });

  // const response = await fetch('https://app.kycel.com/v1/login', {
  //   mode: 'cors', 
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     "Access-Control-Allow-Origin": "*",
  //     "Access-Control-Allow-Headers" : "Origin, X-Requested-With, Content-Type, Accept"
  //   },
  //   body: JSON.stringify({
  //     "email":"shen@admin.com",
  //     "password": "123456"
  //   }),
  // })
  // const data = await response.json()
  // console.log(data)
  

} 


const MainPageLayout = () => (
  <div>
    <Menu fixed='top' inverted>
      <Container>
        <Menu.Item as='a' header>
          <Image size='mini' src='/logo.png' style={{ marginRight: '1.5em' }} />
          Kycel
        </Menu.Item>
        <Menu.Item as='a'>Home</Menu.Item>
        <Menu.Item as='a'>System</Menu.Item>

        <Dropdown item simple text='Business'>
          <Dropdown.Menu>
            <Dropdown.Item>List Item</Dropdown.Item>
            <Dropdown.Item>List Item</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Header>Header Item</Dropdown.Header>
            <Dropdown.Item>
              <i className='dropdown icon' />
              <span className='text'>Submenu</span>
              <Dropdown.Menu>
                <Dropdown.Item>List Item</Dropdown.Item>
                <Dropdown.Item>List Item</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown.Item>
            <Dropdown.Item>List Item</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Container>
    </Menu>

    <Container text style={{ marginTop: '7em' }}>
      <Header as='h1'>Kycel Business Puzzle Parts</Header>
      {/* color='orange' */}
      <Card  fluid >
        <CardContent>
          <Image
            floated='right'
            size='mini'
            // src='/images/avatar/large/molly.png'
          />
          <CardHeader>Login</CardHeader>
          <CardMeta>Link: api.kycel.com/v1/user/login</CardMeta>
          <CardDescription>Description: Açıklama </CardDescription>
          <CardDescription>
            <Form>
              <FormField>
                <label>Login</label>
                <input placeholder='Username' />
              </FormField>
              <FormField>
                <input placeholder='Password' />
              </FormField>
              <FormField>
                <Checkbox label='I agree to the Terms and Conditions' />
              </FormField>
            </Form>
          </CardDescription>
        </CardContent>
        <CardContent extra>
          <div className='ui two buttons'>

            
            <Button type='submit' basic color='green' onClick={login} >
              Login
            </Button>
            <Button basic color='red' onClick={test} >
              Decline
            </Button>
          </div>
        </CardContent>
      </Card>
    </Container>

    <Segment inverted vertical style={{ margin: '5em 0em 0em', padding: '5em 0em' }}>
      <Container textAlign='center'>
        <Grid divided inverted stackable>
          <Grid.Column width={3}>
            <Header inverted as='h4' content='Group 1' />
            <List link inverted>
              <List.Item as='a'>Link One</List.Item>
              <List.Item as='a'>Link Two</List.Item>
              <List.Item as='a'>Link Three</List.Item>
              <List.Item as='a'>Link Four</List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={3}>
            <Header inverted as='h4' content='Group 2' />
            <List link inverted>
              <List.Item as='a'>Link One</List.Item>
              <List.Item as='a'>Link Two</List.Item>
              <List.Item as='a'>Link Three</List.Item>
              <List.Item as='a'>Link Four</List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={3}>
            <Header inverted as='h4' content='Group 3' />
            <List link inverted>
              <List.Item as='a'>Link One</List.Item>
              <List.Item as='a'>Link Two</List.Item>
              <List.Item as='a'>Link Three</List.Item>
              <List.Item as='a'>Link Four</List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={7}>
            <Header inverted as='h4' content='Footer Header' />
            <p>
              Extra space for a call to action inside the footer that could help re-engage users.
            </p>
          </Grid.Column>
        </Grid>

        <Divider inverted section />
        <Image centered size='mini' src='/logo.png' />
        <List horizontal inverted divided link size='small'>
          <List.Item as='a' href='#'>
            Site Map
          </List.Item>
          <List.Item as='a' href='#'>
            Contact Us
          </List.Item>
          <List.Item as='a' href='#'>
            Terms and Conditions
          </List.Item>
          <List.Item as='a' href='#'>
            Privacy Policy
          </List.Item>
        </List>
      </Container>
    </Segment>
  </div>
)

export default MainPageLayout
